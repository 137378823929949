import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const languages = ['en', 'fr', 'es', 'ht']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    whitelist: languages,
    interpolation: {
      escapeValue: false,
    },
    react: {
      // ...
      hashTransKey: function (defaultValue) {
        // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
      },
      defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'], // don't convert to <1></1> if simple react elements
    },
  })

export default i18n
