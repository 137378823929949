import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import './Footer.css'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation(['header'])
  return (
    <footer className='footer'>
      <Container>
        <Row>
          <Col lg={2} md={6} sm={6}>
            <div className='d-flex'>
              <p>New York, NY</p>
            </div>
            <div className='d-flex'>
              <a href='tel:+16466535327'>+1-646-653-5327</a>
            </div>
          </Col>
          <Col lg={6} md={2} sm={6}>
            <Row>
              <Col>
                <a href='/#services' className='footer-nav'>
                  {t('header:Services')}
                </a>
              </Col>
              <Col>
                <a href='/#about' className='footer-nav'>
                  {t('header:About')}
                </a>
              </Col>
              <Col>
                <a href='/#contacts' className='footer-nav'>
                  {t('header:Contact')}
                </a>
              </Col>
              <Col>
                <a href='/privacy' className='footer-nav'>
                  {t('header:Privacy Policy')}
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={5} sm={6} className='align-items-center'>
            <div className='d-flex justify-content-center'>
              <FacebookShareButton
                url={process.env.REACT_APP_HOST}
                quote={'Tropical Zest Nutrition'}
                hashtag='#nutrition #dietitian #haitian'
              >
                <FacebookIcon className='mx-3' size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={process.env.REACT_APP_HOST}
                quote={'Tropical Zest Nutrition'}
                hashtag='#nutrition #dietitian #haitian'
              >
                <TwitterIcon className='mx-3' size={36} />
              </TwitterShareButton>
              <WhatsappShareButton
                url={process.env.REACT_APP_HOST}
                quote={'Tropical Zest Nutrition'}
                hashtag='#nutrition #dietitian #haitian'
              >
                <WhatsappIcon className='mx-3' size={36} />
              </WhatsappShareButton>
              <LinkedinShareButton
                url={process.env.REACT_APP_HOST}
                quote={'Tropical Zest Nutrition'}
                hashtag='#nutrition #dietitian #haitian'
              >
                <LinkedinIcon className='mx-3' size={36} />
              </LinkedinShareButton>
              <EmailShareButton
                subject={'Tropical Zest Nutrition'}
                body={process.env.REACT_APP_HOST}
              >
                <EmailIcon className='mx-3' size={36} />
              </EmailShareButton>
            </div>
            <p className='pt-3 text-center'>
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Tropical Zest Nutrition, LLC | All
              Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
