import {
  COUPON_LIST_REQUEST,
  COUPON_LIST_SUCCESS,
  COUPON_LIST_FAIL,
  COUPON_LIST_RESET,
  COUPON_CREATE_REQUEST,
  COUPON_CREATE_SUCCESS,
  COUPON_CREATE_FAIL,
  COUPON_CREATE_RESET,
  COUPON_APPLY_REQUEST,
  COUPON_APPLY_SUCCESS,
  COUPON_APPLY_FAIL,
  COUPON_APPLY_RESET,
  COUPON_GET_REQUEST,
  COUPON_GET_SUCCESS,
  COUPON_GET_FAIL,
  COUPON_GET_RESET,
} from '../constants/couponConstants'
import firebase from '../firebase/firebase'

export const couponListReducer = (state = { coupons: [] }, action) => {
  switch (action.type) {
    case COUPON_LIST_REQUEST:
      return { loading: true }
    case COUPON_LIST_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, coupons: action.payload }
    case COUPON_LIST_FAIL:
      return { loading: false, error: action.payload }
    case COUPON_LIST_RESET:
      return {}
    default:
      return state
  }
}

export const couponCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_CREATE_REQUEST:
      return {}
    case COUPON_CREATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { success: true }
    case COUPON_CREATE_FAIL:
      return { error: action.payload }
    case COUPON_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const couponApplyReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_APPLY_REQUEST:
      return {}
    case COUPON_APPLY_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { success: true, coupon: action.payload }
    case COUPON_APPLY_FAIL:
      return { error: action.payload }
    case COUPON_APPLY_RESET:
      return {}
    default:
      return state
  }
}

export const couponGetReducer = (state = {}, action) => {
  switch (action.type) {
    case COUPON_GET_REQUEST:
      return {}
    case COUPON_GET_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { coupon: action.payload }
    case COUPON_GET_FAIL:
      return { error: action.payload }
    case COUPON_GET_RESET:
      return {}
    default:
      return state
  }
}
