import moment from 'moment'
import React from 'react'
import { Button, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Loader from '../Loader/Loader'
import Message from '../Message/Message'
import './AppointmentAvailability.css'

const AppointmentAvailability = ({
  date,
  loading,
  slots,
  onChange,
  active,
}) => {
  const { t } = useTranslation()

  return (
    <div className='availability'>
      {loading && <Loader />}

      <Container>
        <h2>{date && moment(date).startOf('day').format('LL')}</h2>
        {slots && slots.length === 0 && (
          <Message>{t('No Appointments')}.</Message>
        )}
        <Row>
          {slots &&
            slots.map((slot) => (
              <Button
                key={slot.slot}
                className={
                  active === slot.slot ? 'mb-1 mr-1 active' : 'mb-1 mr-1'
                }
                onClick={() =>
                  onChange({
                    _id: slot._id,
                    slot: slot.slot,
                    time: slot.time,
                  })
                }
              >
                {slot.time}
              </Button>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default AppointmentAvailability
