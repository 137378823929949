import React from 'react'
import { Helmet } from 'react-helmet'
import './Meta.css'

const Meta = ({ title, keyword }) => {
  return (
    <Helmet>
      <title>
        {title
          ? `Tropical Zest Nutrition | ${title}`
          : 'Welcome To Tropical Zest Nutrition'}
      </title>
      <meta name='keyword' content={keyword} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Welcome To Tropical Zest Nutrition',
  keyword: 'Workout, Health, Fitness',
}

export default Meta
