import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
// import Feed from 'react-instagram-authless-feed'
// import ErrorBoundary from './ErrorBoundary'
import { useTranslation } from 'react-i18next'
import './SocialFollow.css'
import { Col, Row } from 'react-bootstrap'

// https://www.digitalocean.com/community/tutorials/creating-a-social-follow-component-in-react

export default function SocialFollow() {
  const { t } = useTranslation()
  return (
    <div className='social-container'>
      <Row>
        <Col>
          <h1>{t('Follow Me')}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            href='https://www.instagram.com/tropical.nutritionist/'
            className='instagram social'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faInstagram} size='2x' />
          </a>
          <a
            href='https://www.linkedin.com/in/genevieve-ca%C3%AFdor-ms-rd-cdn-cdces-b63a0ab7/'
            className='linkedin social'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FontAwesomeIcon icon={faLinkedin} size='2x' />
          </a>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            href='https://www.instagram.com/tropical.nutritionist/'
            className='instagram social'
            target='_blank'
            rel='noopener noreferrer'
          >
            <h2>@tropical.nutritionist</h2>
          </a>
        </Col>
      </Row>

      {/* <ErrorBoundary>
        <Feed
          userName='tropical.nutritionist'
          className='feed'
          classNameLoading='Loading'
          limit='10'
        />
      </ErrorBoundary> */}
    </div>
  )
}
