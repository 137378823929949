import React, { useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useSelector } from 'react-redux'
import './AppointmentCalendar.css'

const AppointmentCalendar = ({
  onClickMonth,
  tileDisabled,
  selectDateHandler,
  tileClassName,
  maxDetail,
}) => {
  const languageSelect = useSelector((state) => state.languageSelect)
  const { language } = languageSelect

  const [value, onChange] = useState(new Date())

  const changeHandler = (date) => {
    onChange(date)
    selectDateHandler(date.toISOString().split('T')[0])
  }

  const viewChangeHandler = ({ activeStartDate }) => {
    const date = activeStartDate.toISOString().split('T')[0]
    onClickMonth(date)
  }

  return (
    <div className='calendar'>
      <Calendar
        onChange={changeHandler}
        value={value}
        tileDisabled={tileDisabled}
        onClickMonth={onClickMonth}
        tileClassName={tileClassName}
        locale={language}
        onActiveStartDateChange={viewChangeHandler}
        maxDetail={maxDetail || 'month'}
      />
    </div>
  )
}

export default AppointmentCalendar
