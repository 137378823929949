import React from 'react'
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../../actions/userActions'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['header'])

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <Navbar expand='lg' collapseOnSelect fixed='top' className='header'>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand className='logo'>
            <img
              alt=''
              src='/favicon-32x32.png'
              width='30'
              height='30'
              className='d-inline-block align-top'
            />{' '}
            <span className='title tropical' translate="no">Tropical</span>{' '}
            <span className='title zest' translate="no" >Zest</span>{' '}
            <span className='title nutrition' translate="no" >Nutrition</span>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls='basic-navbar-nav'>
          <FontAwesomeIcon icon={faBars} style={{ color: '#fff' }} />
        </Navbar.Toggle>

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <LinkContainer to='/#about'>
              <Nav.Link>{t('header:About')}</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/#services'>
              <Nav.Link>{t('header:Services')}</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/#contacts'>
              <Nav.Link>{t('header:Contact')}</Nav.Link>
            </LinkContainer>
            {
              userInfo && userInfo.isAdmin ? (
                <NavDropdown
                  title={
                    <>
                      {userInfo.photoURL ? (
                        <Image
                          roundedCircle
                          fluid
                          src={userInfo.photoURL}
                          alt={userInfo.name}
                          width='30'
                          height='30'
                        />
                      ) : (
                        userInfo.name
                      )}
                    </>
                  }
                  id='username'
                >
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    {t('Logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <></>
              )
              // (
              //   <LinkContainer to='/login'>
              //     <Nav.Link>
              //       <i className='fas fa-user'></i> {t('Sign In')}
              //     </Nav.Link>
              //   </LinkContainer>
              // )
            }
            {userInfo && userInfo.isAdmin && (
              <NavDropdown title='Admin' id='adminmenu'>
                {/* <LinkContainer to='/admin/userList'>
                  <NavDropdown.Item>{t('Users')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/productList'>
                  <NavDropdown.Item>{t('Products')}</NavDropdown.Item>
                </LinkContainer> */}
                <LinkContainer to='/admin/appointmentList'>
                  <NavDropdown.Item>{t('Appointments')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/coupons'>
                  <NavDropdown.Item>{t('Coupons')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            <LanguageSelector />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
