import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import author from '../../images/me.jpeg'
import './About.css'

const About = () => {
  const { t } = useTranslation(['about'])
  return (
    <Container id='about' className='about'>
      <div className='row'>
        <div className='col-lg-6 col-xm-12'>
          <div className='fill'>
            <img src={author} alt='' />
          </div>
        </div>
        <div className='col-lg-6 col-xm-12'>
          <h1 className='about-heading'>{t('about:about me')}</h1>
          <p>{t('about:paragraph1')}</p>
          <p>{t('about:paragraph2')}</p>
          <p>{t('about:paragraph3')}</p>
          <p>{t('about:paragraph4')}</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            {t('about:paragraph5')}
          </p>
        </div>
      </div>
    </Container>
  )
}

export default About
