import React, { useState } from 'react'
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Message from '../Message/Message'
import PayPalPayButton from '../PaypalButton/PaypalButton'
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import './AppointmentSummary.css'

const AppointmentSummary = ({
  product,
  appointment,
  successPaymentHandler,
  onProceed,
}) => {
  const { t } = useTranslation()
  const [accepted, setAccepted] = useState(false)

  const isValid = (appointment) => {
    return appointment.date && appointment.time && appointment.phone
  }

  const couponDiscount =
    product.price && appointment.coupon
      ? product.price * appointment.coupon.value
      : 0

  return (
    <div>
      <Card>
        <ListGroup variant='flush'>
          <ListGroup.Item>
            <h2>{product.name}</h2>
          </ListGroup.Item>
          <ListGroup.Item>
            {!appointment.date ? (
              <Message>{t('Please fill out details')}</Message>
            ) : (
              <>
                <h3>{t('Appointment Details')}</h3>
                <Row>
                  <Col>
                    <strong>Date: </strong>
                  </Col>
                  <Col>
                    {appointment.date && appointment.date.format('dddd LL')}
                  </Col>
                </Row>
                {appointment.time && (
                  <Row>
                    <Col>
                      <strong>{t('Time')}: </strong>
                    </Col>
                    <Col>{appointment.time}</Col>
                  </Row>
                )}
                {appointment.phone && (
                  <Row>
                    <Col>
                      <strong>{t('Phone')}: </strong>
                    </Col>
                    <Col>{appointment.phone}</Col>
                  </Row>
                )}
                <hr />
                <h3>{t('Payment information')}</h3>
                <Row>
                  <Col>
                    <strong>{t('Subtotal')}: </strong>
                  </Col>
                  <Col>${product.price.toFixed(2)}</Col>
                </Row>
                {couponDiscount !== 0 && (
                  <Row>
                    <Col>
                      <strong>Coupon: </strong>
                    </Col>
                    <Col>-${couponDiscount.toFixed(2)}</Col>
                  </Row>
                )}
                <Row>
                  <Col></Col>
                  <Col>----------</Col>
                </Row>
                <Row>
                  <Col>
                    <strong>{t('Grand Total')}: </strong>
                  </Col>
                  <Col>${(product.price - couponDiscount).toFixed(2)}</Col>
                </Row>
                <hr />
                <input
                  type='checkbox'
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                />
                <TermsAndConditions onAccept={() => setAccepted(true)} />
                {accepted &&
                  isValid(appointment) &&
                  product.price - couponDiscount === 0 && (
                    <>
                      <hr />
                      <Button
                        type='button'
                        className='btn-block'
                        disabled={!isValid(appointment)}
                        onClick={onProceed}
                      >
                        {t('Book Now')}
                      </Button>
                    </>
                  )}
                {accepted &&
                  isValid(appointment) &&
                  product.price - couponDiscount !== 0 &&
                  appointment.email && (
                    <>
                      <hr />
                      <PayPalPayButton
                        amount={product.price - couponDiscount}
                        onSuccess={successPaymentHandler}
                      />
                    </>
                  )}
              </>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  )
}

export default AppointmentSummary
