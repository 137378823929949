import {
  LANGUAGE_SELECT_FAIL,
  LANGUAGE_SELECT_REQUEST,
  LANGUAGE_SELECT_RESET,
  LANGUAGE_SELECT_SUCCESS,
} from '../constants/sharedConstants'
import firebase from '../firebase/firebase'

export const languageSelectReducer = (state = { language: 'en' }, action) => {
  switch (action.type) {
    case LANGUAGE_SELECT_REQUEST:
      return { ...state }
    case LANGUAGE_SELECT_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { language: action.payload }
    case LANGUAGE_SELECT_FAIL:
      return { error: action.payload }
    case LANGUAGE_SELECT_RESET:
      return { language: 'en' }
    default:
      return state
  }
}
