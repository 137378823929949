import React, { useState } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import Message from '../Message/Message'
import './PaypalButton.css'

const CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  production: process.env.REACT_APP_PAYPAL_CLIENT_ID,
}

const CLIENT_ID =
  process.env.NODE_ENV === 'production' ? CLIENT.production : CLIENT.sandbox

const PayPalPayButton = ({ amount, onSuccess }) => {
  const [processing, setProcessing] = useState(false)

  return (
    <>
      {processing && <Message>Processing Payment... Do not refresh.</Message>}
      <PayPalButton
        createOrder={(data, actions) => {
          setProcessing(true)
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: amount,
                },
              },
            ],
          })
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            onSuccess(details)
            setProcessing(false)
          })
        }}
        onError={(error) => {
          console.log('This is inside onError Paypal button ' + JSON.stringify(error))
          setProcessing(false)
        }}
        onCancel={(data) => {
          console.log('This is inside onCancel Paypal button')
          setProcessing(false)
        }}
        options={{
          clientId: CLIENT_ID,
        }}
      />
    </>
  )
}

export default PayPalPayButton
