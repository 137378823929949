import moment from 'moment'
import React from 'react'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  ListGroup,
} from 'react-bootstrap'
import './AppointmentListSummary.css'

// const PAYPAL_URL = process.env.REACT_APP_PAYPAL_URL

const AppointmentListSummary = ({ appointment, onProceed, onFollowup }) => {
  return (
    <div>
      {appointment && (
        <Card>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Payment Details</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              {appointment._id && (
                <p>
                  <strong>Confirmation Number: </strong>
                  {appointment._id}
                </p>
              )}
              <p>
                <strong>Paid: </strong>
                {appointment.paidBy
                  ? 'Yes'
                  : appointment.paymentInfo
                  ? 'Yes'
                  : 'No'}
              </p>
              {appointment.paidBy && (
                <>
                  <p>
                    <strong>Paid By: </strong>
                    {appointment.paidBy.name}
                  </p>
                  <p>
                    <strong>Paid On: </strong>
                    {appointment.paidBy.update_time.split('T')[0]}
                  </p>
                </>
              )}
              <p>
                <strong>Amount: </strong>$
                {appointment.paymentInfo && appointment.paymentInfo.total}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Appointment Details</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              {appointment.name && (
                <p>
                  <strong>Name: </strong>
                  {appointment.name}
                </p>
              )}
              {appointment.status && (
                <p>
                  <strong>Status: </strong>
                  {appointment.status}
                </p>
              )}
              <p>
                <strong>Date: </strong>
                {appointment.date &&
                  moment(appointment.date, 'YYYY-MM-DD')
                    .startOf('day')
                    .format('dddd MMMM Do[,] YYYY')}
              </p>
              {appointment.time && (
                <p>
                  <strong>Time: </strong>
                  {appointment.time}
                </p>
              )}
              {appointment.email && (
                <p>
                  <strong>Email: </strong>
                  {appointment.email}
                </p>
              )}
              {appointment.phone && (
                <p>
                  <strong>Phone: </strong>
                  {appointment.phone}
                </p>
              )}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup.Item>
            <ButtonToolbar aria-label='Toolbar with button groups'>
              <ButtonGroup className='mr-2' aria-label='First group'>
                <Button
                  variant='outline-primary'
                  onClick={onFollowup}
                  style={{ color: '#00008B' }}
                >
                  Schedule Followup
                </Button>
              </ButtonGroup>
              {appointment.status && appointment.status === 'SCHEDULED' && (
                <ButtonGroup className='mr-2' aria-label='First group'>
                  <Button
                    variant='outline-primary'
                    onClick={onProceed}
                    style={{ color: '#00008B' }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              )}
              {appointment.paidBy && (
                <ButtonGroup className='mr-2' aria-label='First group'>
                  <Button variant='outline-primary'>
                    <a
                      href='https://www.paypal.com/activities'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Refund
                    </a>
                  </Button>
                </ButtonGroup>
              )}
            </ButtonToolbar>
          </ListGroup.Item>
        </Card>
      )}
    </div>
  )
}

export default AppointmentListSummary
