import moment from 'moment'
import 'moment/locale/fr'
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  createAppointment,
  getAvailableDays,
  getAvailableSlots,
} from '../actions/appointmentActions'
import AppointmentAvailability from '../components/AppointmentAvailability/AppointmentAvailability'
import AppointmentCalendar from '../components/AppointmentCalendar/AppointmentCalendar'
import AppointmentSummary from '../components/AppointmentSummary/AppointmentSummary'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { selectProduct } from '../actions/productActions'
import { APPOINTMENT_CREATE_RESET } from '../constants/appointmentConstants'
import { COUPON_GET_RESET } from '../constants/couponConstants'

const AppointmentBookingScreen = ({ currentAppointment, onSuccess }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  // const token = executeRecaptcha('appointment_booking_screen')

  const { t } = useTranslation()

  const [values, setValues] = useState({
    email: currentAppointment.email,
    phone: currentAppointment.phone,
    client_name: currentAppointment.client_name,
  })

  const { date, slot, phone, time, coupon, email, client_name } = values

  const appointmentSlotList = useSelector((state) => state.appointmentSlotList)
  const { loading, slots } = appointmentSlotList

  const appointmentCreate = useSelector((state) => state.appointmentCreate)
  const { success: successAppointmentCreate, appointment } = appointmentCreate

  const languageSelect = useSelector((state) => state.languageSelect)
  const { language } = languageSelect

  const productSelect = useSelector((state) => state.productSelect)
  const { product } = productSelect

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(selectProduct(language, 'followup'))
  }, [dispatch, language])

  //Validate Inputs
  useEffect(() => {
    if (!date || date === '') {
    } else if (!slot || slot < 0) {
    } else if (!phone || phone === '') {
    } else {
    }
  }, [date, slot, phone])

  useEffect(() => {
    if (successAppointmentCreate) {
      const confirmationMessage = `${t('appointment confirmation', {
        id: appointment._id,
      })}`
      onSuccess()
      alert(confirmationMessage)
      dispatch({ type: APPOINTMENT_CREATE_RESET })
      dispatch({ type: COUPON_GET_RESET })
    }
  }, [dispatch, successAppointmentCreate, t, appointment, onSuccess])

  //https://api.sandbox.paypal.com/v2/payments/captures/8R1351426G469811A/refund
  const submitHandler = (paymentResult) => {
    // e.preventDefault()
    const paidBy = {
      id: paymentResult.id,
      status: paymentResult.status,
      update_time: paymentResult.update_time,
      email_address: paymentResult.payer.email_address,
      name: paymentResult.payer.name.given_name,
    }

    const newAppointment = {
      date: date.format('YYYY-MM-DD'),
      slot,
      phone,
      email,
      span: 1,
      name: product.name,
      client_name,
      paymentInfo: {
        subtotal: product.price,
        total: product.price,
      },
      paidBy,
      status: 'SCHEDULED',
    }

    const couponDiscount =
      product.price && coupon && coupon === 'LAUNCH2021'
        ? product.price * -0.1
        : 0

    if (couponDiscount !== 0) {
      newAppointment.paymentInfo.coupon = {
        code: coupon,
        value: couponDiscount,
      }
      newAppointment.paymentInfo.total = product.price + couponDiscount
    }
    dispatch(createAppointment(newAppointment, executeRecaptcha))
  }

  const onProceed = (e) => {
    e.preventDefault()

    const newAppointment = {
      date: date.format('YYYY-MM-DD'),
      slot,
      phone,
      email,
      span: 1,
      name: product.name,
      client_name,
      status: 'SCHEDULED',
      paymentInfo: {
        subtotal: product.price,
        total: product.price,
      },
    }

    const couponDiscount =
      product.price && coupon && coupon === 'LAUNCH2021'
        ? product.price * -0.1
        : 0

    if (couponDiscount !== 0) {
      newAppointment.paymentInfo.coupon = {
        code: coupon,
        value: couponDiscount,
      }
      newAppointment.paymentInfo.total = product.price + couponDiscount
    }
    dispatch(createAppointment(newAppointment, executeRecaptcha))
  }

  const selectDateHandler = (e) => {
    setValues({
      ...values,
      slot: -1,
      time: null,
      date: moment(e, 'YYYY-MM-DD').startOf('day'),
    })
    dispatch(getAvailableSlots(e))
  }

  const onClickMonth = (month) => {
    dispatch(getAvailableDays(moment(month, 'YYYY-MM-DD').format('YYYY-MM-DD')))
  }

  const tileDisabled = ({ date, view }) => {
    // Disable tiles in month view only
    if (view === 'month') {
      const dateStr = date.toISOString().split('T')[0]
      const isFull = slots && slots[dateStr] && slots[dateStr]['full']
      const isSunday =
        date.getDay() === 0 ||
        date.getDay() === 1 ||
        date.getDay() === 2 ||
        date.getDay() === 3
      const isPast = moment().isAfter(dateStr)
      return isFull || isSunday || isPast
    }
  }

  return (
    <Container id='appointmentbooking' className='appointmentbooking'>
      <Row>
        <>
          <Col>
            <h2>{t('Pick a Date and Time')}</h2>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId='date'>
                <AppointmentCalendar
                  onClickMonth={onClickMonth}
                  tileDisabled={tileDisabled}
                  selectDateHandler={selectDateHandler}
                />
              </Form.Group>
              {date && (
                <Form.Group controlId='time'>
                  <AppointmentAvailability
                    date={moment(date, 'YYYY-MM-DD')
                      .startOf('day')
                      .format('YYYY-MM-DD')}
                    loading={loading}
                    slots={slots}
                    onChange={(slot) =>
                      setValues({
                        ...values,
                        slot: slot.slot,
                        time: slot.time,
                      })
                    }
                  />
                </Form.Group>
              )}

              {slot > -1 && (
                <Form.Group controlId='client_name'>
                  <Form.Control
                    type='text'
                    placeholder={'Enter your name'}
                    value={client_name || ''}
                    onChange={(e) =>
                      setValues({ ...values, client_name: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>
              )}

              {slot > -1 && (
                <Form.Group controlId='phone'>
                  <Form.Control
                    type='text'
                    placeholder={'Enter Phone'}
                    value={phone || ''}
                    onChange={(e) =>
                      setValues({ ...values, phone: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>
              )}

              {slot > -1 && phone && (
                <Form.Group controlId='email'>
                  <Form.Control
                    type='email'
                    placeholder={'Enter email'}
                    value={email || ''}
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>
              )}
            </Form>
          </Col>
          <Col>
            {product && (
              <AppointmentSummary
                product={product}
                appointment={{ date, time, phone, coupon, email }}
                onProceed={onProceed}
                successPaymentHandler={submitHandler}
              />
            )}
          </Col>
        </>
      </Row>
    </Container>
  )
}

export default AppointmentBookingScreen
