import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const CouponForm = ({ show, handleClose, handleSave }) => {
  const [values, setValues] = useState({ discount: 1, max_use: 10 })
  const { discount, max_use } = values

  const onSave = () => {
    handleClose()
    handleSave({ discount, max_use })
    setValues({ discount: 1, max_use: 10 })
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Coupon</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId='formBasicDiscount'>
              <Form.Control
                type='number'
                placeholder='Enter discount'
                value={discount}
                onChange={(e) =>
                  setValues({ ...values, discount: e.target.value })
                }
              />
              <Form.Text className='text-muted'>
                The % discount. This must be a number between 1 and 100.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId='formBasicMaxUsage'>
              <Form.Control
                type='number'
                placeholder='Max Usage'
                value={max_use}
                onChange={(e) =>
                  setValues({ ...values, max_use: e.target.value })
                }
              />
              <Form.Text className='text-muted'>
                This is the maximum number of time this coupon can be used.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CouponForm
