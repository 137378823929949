import {
  LANGUAGE_SELECT_REQUEST,
  LANGUAGE_SELECT_SUCCESS,
  LANGUAGE_SELECT_FAIL,
} from '../constants/sharedConstants'

export const selectLanguage = (lng) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LANGUAGE_SELECT_REQUEST,
    })

    dispatch({
      type: LANGUAGE_SELECT_SUCCESS,
      payload: lng,
    })
  } catch (error) {
    dispatch({
      type: LANGUAGE_SELECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
