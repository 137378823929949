import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import './Counseling.css'

const Counseling = () => {
  const { t } = useTranslation(['counseling'])

  const conditions = [
    t('counseling:Pre-diabetes / Insulin Resistance'),
    t('counseling:Diabetes'),
    t('counseling:Hypertension'),
    t('counseling:Cholesterol'),
    t('counseling:Weight gain Management'),
    t('counseling:General Healthy Eating / Wellness'),
  ]

  return (
    <Container id='counseling' className='counseling'>
      <h1>{t('counseling:Nutrition counseling')}</h1>

      <Trans i18nKey='counseling:content'>
        <p>
          While working with me, you will learn about listening to your body’s
          cues while providing it optimal nourishment to help it thrive. I will
          provide you with the tools to help you make long term sustainable
          changes (not a quick fix!) to improve your health, your mental and
          physical wellness.
        </p>
        <p>
          During your initial consultation with me, we will discuss your current
          lifestyle, develop your personalized and flexible nutrition plan and
          establish short and long-term goals.
        </p>
        <p>
          During your follow up appointments, we will discuss your progress and
          challenges encountered to help you achieve your goals.
        </p>
      </Trans>

      <Row>
        <Col>
          <h2>{t('counseling:How I can help?')}</h2>
          <ul>
            {conditions.map((condition, i) => (
              <li key={i}>{condition}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Trans i18nKey='counseling:message'>
        <p>
          Sessions will be virtually through phone or a secure video platform.
          Insurance is currently accepted, with additional health plans that may
          be eligible for insurance reimbursement.
        </p>
      </Trans>
    </Container>
  )
}

export default Counseling
