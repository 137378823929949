import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_SELECT_REQUEST,
  PRODUCT_SELECT_SUCCESS,
  PRODUCT_SELECT_FAIL,
  PRODUCT_SELECT_RESET,
} from '../constants/productConstants'
import firebase from '../firebase/firebase'

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true }
    case PRODUCT_LIST_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, products: action.payload }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_LIST_RESET:
      return { products: [] }
    default:
      return state
  }
}

export const productSelectReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_SELECT_REQUEST:
      return { loading: true }
    case PRODUCT_SELECT_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, product: action.payload }
    case PRODUCT_SELECT_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_SELECT_RESET:
      return {}
    default:
      return state
  }
}
