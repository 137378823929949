import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createCoupon, listCoupon } from '../actions/couponActions'
import MaterialTable from 'material-table'
import moment from 'moment'
import { useState } from 'react'
import CouponForm from '../components/CouponForm/CouponForm'
import { validateToken } from '../actions/authActions'

const CouponScreen = ({ history }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    dispatch(validateToken())
    setShow(true)
  }

  const couponList = useSelector((state) => state.couponList)
  const { coupons } = couponList

  const couponCreate = useSelector((state) => state.couponCreate)
  const { success: successCouponCreate } = couponCreate

  const authToken = useSelector((state) => state.authToken)
  const {
    error: errorAuthToken,
    validating,
    success: successAuthToken,
  } = authToken

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(validateToken())
    if (successCouponCreate) {
      dispatch(listCoupon())
    }
  }, [dispatch, successCouponCreate])

  useEffect(() => {
    dispatch(validateToken())
    dispatch(listCoupon())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (errorAuthToken) {
      history.push('/')
    }
    if (!validating && !successAuthToken && !errorAuthToken) {
      history.push('/')
    }
  }, [history, errorAuthToken, successAuthToken, validating])

  const handleCreateCoupon = (e) => {
    const { discount, max_use } = e
    if (
      window.confirm(
        `Generate a coupon for ${discount}% that can be used ${max_use} times?`
      )
    ) {
      dispatch(
        createCoupon({
          value: 1 * discount,
          max_use: 1 * max_use,
        })
      )
    }
  }

  const columns = [
    {
      title: 'Code',
      field: '_id',
    },
    {
      title: 'Discount',
      field: 'value',
      render: (rowData) => `${rowData.value * 100}%`,
    },
    {
      title: 'Available',
      field: 'max_use',
      render: (rowData) => `${rowData.max_use - rowData.used_count}`,
    },
    {
      title: 'Used',
      field: 'used_count',
    },
    {
      title: 'Expiration',
      field: 'expiration_dt',
      render: (rowData) =>
        moment(new Date(rowData.expiration_dt._seconds * 1000)).format('LL'),
    },
  ]

  return (
    <Container>
      <Row className='align-items-center'>
        <Col>
          <h1>Coupons</h1>
        </Col>
        <Col className='text-right'>
          <Button className='mb-1' onClick={handleShow}>
            New Coupon
          </Button>
        </Col>
      </Row>
      <CouponForm
        show={show}
        handleClose={handleClose}
        handleSave={handleCreateCoupon}
      />
      <MaterialTable title='' data={coupons} columns={columns} />
    </Container>
  )
}

export default CouponScreen
