import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_SELECT_REQUEST,
  PRODUCT_SELECT_SUCCESS,
  PRODUCT_SELECT_FAIL,
  // PRODUCT_LIST_RESET,
} from '../constants/productConstants'

const products = {
  en: [
    {
      _id: '1',
      name: 'Introductory package',
      duration: '2 sessions',
      description: [
        'One 45 minutes initial consult',
        'One 20 minutes follow up session appointment',
      ],
      price: 180,
      image: 'images/p-olena.jpg',
    },
    {
      _id: '2',
      name: '3 Month Package',
      duration: '4 sessions',
      description: [
        'One 45 minutes initial consult',
        'Three 20 minutes follow up appointments',
      ],
      price: 400,
      image: 'images/p-heather.jpg',
    },
    {
      _id: '3',
      name: '6 Month Package',
      duration: '8 sessions',
      description: [
        'One 45 minutes initial consult',
        'Seven 20 minutes follow up appointments',
      ],
      price: 600,
      image: 'images/p-alison.jpg',
    },
    {
      _id: 'free',
      name: 'Discovery Call',
      duration: '10 minutes',
      description: [
        'Not sure on how I can help you? Schedule a free discovery call and I will contact you to discuss your dietary goals and how I can help you as a dietitian.',
      ],
      price: 0,
      image: 'images/discovery-v3.jpeg',
    },
    {
      _id: 'followup',
      name: 'Followup Call',
      duration: '20 minutes',
      description: ['Followup call.'],
      price: 0,
      image: 'images/discovery-v3.jpeg',
    },
  ],
  fr: [
    {
      _id: '1',
      name: 'Condition Spécifique',
      duration: '2 consultations',
      description: [
        'Une consultation de 45 minutes',
        'Une consultation de suivi de 20 minutes dans moins de 2 mois',
      ],
      price: 180,
      image: 'images/p-olena.jpg',
    },
    {
      _id: '2',
      name: 'Forfait de 3 Mois',
      duration: '4 consultations',
      description: [
        'Une consultation de 45 minutes',
        'Trois consultations de suivi de  20 minutes chacune',
      ],
      price: 400,
      image: 'images/p-heather.jpg',
    },
    {
      _id: '3',
      name: 'Forfait de 6 Mois',
      duration: '8 consultations',
      description: [
        'Une consultation de 45 minutes',
        '7 consultations de suivi de 20 minutes chacune',
      ],
      price: 600,
      image: 'images/p-alison.jpg',
    },
    {
      _id: 'free',
      name: 'Conseils Nutritionnels',
      duration: '10 minutes',
      description: [
        'Pas trop sûre comment je peux vous aider? Planifiez un appel découverte gratuit et je vous contacterai pour discuter de vos objectifs alimentaires et comment je peux vous aider en tant que diététicienne.',
      ],
      price: 0,
      image: 'images/discovery-v3.jpeg',
    },
    {
      _id: 'followup',
      name: 'Followup Call',
      duration: '20 minutes',
      description: ['Followup call.'],
      price: 0,
      image: 'images/discovery-v3.jpeg',
    },
  ],
}

export const listProduct = (language = 'en') => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    })

    const data = products[language]

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const selectProduct = (language = 'en', id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_SELECT_REQUEST,
    })

    const data = products[language].find((p) => p._id === id)

    dispatch({
      type: PRODUCT_SELECT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_SELECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
