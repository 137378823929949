export const APPOINTMENT_CREATE_REQUEST = 'APPOINTMENT_CREATE_REQUEST'
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS'
export const APPOINTMENT_CREATE_FAILED = 'APPOINTMENT_CREATE_FAILED'
export const APPOINTMENT_CREATE_RESET = 'APPOINTMENT_CREATE_RESET'

export const APPOINTMENT_LIST_REQUEST = 'APPOINTMENT_LIST_REQUEST'
export const APPOINTMENT_LIST_SUCCESS = 'APPOINTMENT_LIST_SUCCESS'
export const APPOINTMENT_LIST_FAILED = 'APPOINTMENT_LIST_FAILED'
export const APPOINTMENT_LIST_RESET = 'APPOINTMENT_LIST_RESET'

export const APPOINTMENT_DAYS_AVAILABLE_REQUEST =
  'APPOINTMENT_DAYS_AVAILABLE_REQUEST'
export const APPOINTMENT_DAYS_AVAILABLE_SUCCESS =
  'APPOINTMENT_DAYS_AVAILABLE_SUCCESS'
export const APPOINTMENT_DAYS_AVAILABLE_FAILED =
  'APPOINTMENT_DAYS_AVAILABLE_FAILED'
export const APPOINTMENT_DAYS_AVAILABLE_RESET =
  'APPOINTMENT_DAYS_AVAILABLE_RESET'

export const APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST =
  'APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST'
export const APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS =
  'APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS'
export const APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED =
  'APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED'
export const APPOINTMENT_DAY_SLOTS_AVAILABLE_RESET =
  'APPOINTMENT_DAY_SLOTS_AVAILABLE_RESET'

export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST'
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS'
export const APPOINTMENT_CANCEL_FAIL = 'APPOINTMENT_CANCEL_FAIL'
export const APPOINTMENT_CANCEL_RESET = 'APPOINTMENT_CANCEL_RESET'
