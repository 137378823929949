import React, { useEffect } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { listProduct } from '../../actions/productActions'
import './FreeCall.css'

const FreeCall = ({ history }) => {
  const languageSelect = useSelector((state) => state.languageSelect)
  const { language } = languageSelect

  const productList = useSelector((state) => state.productList)
  const { products } = productList

  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProduct(language))
  }, [language, dispatch])

  const product = products && products.find((p) => p._id === 'free')

  return (
    <Container id='freecall' className='freecall'>
      {product && (
        <div className='row'>
          <div className='col-lg-6 col-xm-12'>
            <div className='freecallFill'>
              <img src={product.image} alt='' />
            </div>
          </div>
          <div className='col-lg-6 col-xm-12'>
            <div>
              <h2>{product.name}:</h2>
              <h2>{product.duration}</h2>
            </div>
            {/* <div>
              {product.price > 0 ? (
                <h3>${product.price}</h3>
              ) : (
                <h3>{t('free')}</h3>
              )}
            </div> */}
            <div>
              <p>{product.description}</p>
            </div>
            <div>
              <Button
                variant='outline-primary'
                onClick={() => history.push(`/#contacts`)}
                style={{ color: '#00008B' }}
              >
                {t('Book Now To Get Started')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default FreeCall
