import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {
  appointmentCreateReducer,
  appointmentListReducer,
  appointmentSlotListReducer,
  appointmentCancelReducer,
} from './reducers/appointmentReducers'
import { authTokenReducer } from './reducers/authReducers'
import { contactFormReducer } from './reducers/contactReducers'
import { languageSelectReducer } from './reducers/sharedReducers'
import { userLoginReducer } from './reducers/userReducers'
import {
  productListReducer,
  productSelectReducer,
} from './reducers/productReducers'
import {
  couponListReducer,
  couponCreateReducer,
  couponApplyReducer,
  couponGetReducer,
} from './reducers/couponReducers'

const reducer = combineReducers({
  appointmentCancel: appointmentCancelReducer,
  appointmentCreate: appointmentCreateReducer,
  appointmentList: appointmentListReducer,
  appointmentSlotList: appointmentSlotListReducer,
  authToken: authTokenReducer,
  contactForm: contactFormReducer,
  couponApply: couponApplyReducer,
  couponCreate: couponCreateReducer,
  couponGet: couponGetReducer,
  couponList: couponListReducer,
  languageSelect: languageSelectReducer,
  productList: productListReducer,
  productSelect: productSelectReducer,
  userLogin: userLoginReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  languageSelect: { language: localStorage.getItem('i18nextLng') || 'en' },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
