import axios from 'axios'
import {
  CONTACT_FORM_SEND_FAILED,
  CONTACT_FORM_SEND_REQUEST,
  CONTACT_FORM_SEND_SUCCESS,
} from '../constants/contactConstants'

export const sendForm = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_FORM_SEND_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    await axios.post('/api/contact', formData, config)

    dispatch({ type: CONTACT_FORM_SEND_SUCCESS })
  } catch (error) {
    dispatch({
      type: CONTACT_FORM_SEND_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
