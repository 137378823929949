export const COUPON_LIST_REQUEST = 'COUPON_LIST_REQUEST'
export const COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS'
export const COUPON_LIST_FAIL = 'COUPON_LIST_FAIL'
export const COUPON_LIST_RESET = 'COUPON_LIST_RESET'

export const COUPON_CREATE_REQUEST = 'COUPON_CREATE_REQUEST'
export const COUPON_CREATE_SUCCESS = 'COUPON_CREATE_SUCCESS'
export const COUPON_CREATE_FAIL = 'COUPON_CREATE_FAIL'
export const COUPON_CREATE_RESET = 'COUPON_CREATE_RESET'

export const COUPON_APPLY_REQUEST = 'COUPON_APPLY_REQUEST'
export const COUPON_APPLY_SUCCESS = 'COUPON_APPLY_SUCCESS'
export const COUPON_APPLY_FAIL = 'COUPON_APPLY_FAIL'
export const COUPON_APPLY_RESET = 'COUPON_APPLY_RESET'

export const COUPON_GET_REQUEST = 'COUPON_GET_REQUEST'
export const COUPON_GET_SUCCESS = 'COUPON_GET_SUCCESS'
export const COUPON_GET_FAIL = 'COUPON_GET_FAIL'
export const COUPON_GET_RESET = 'COUPON_GET_RESET'