import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import AppointmentBookingScreen from './screens/AppointmentBookingScreen'
import AppointmentListScreen from './screens/AppointmentListScreen'
import CouponScreen from './screens/CouponScreen'
import HomeScreen from './screens/HomeScreen'
import LoginScreen from './screens/LoginScreen'
import PrivacyScreen from './screens/PrivacyScreen'

const App = () => {
  return (
    <Router>
      <Header></Header>
      <ErrorBoundary>
        <main>
          <Route path='/' component={HomeScreen} exact />
          <Route
            path='/newappointment/:id'
            component={AppointmentBookingScreen}
          />
          <Route path='/login' component={LoginScreen} />
          <Route
            path='/admin/appointmentList'
            component={AppointmentListScreen}
          />
          <Route path='/privacy' component={PrivacyScreen} />
          <Route path='/admin/coupons' component={CouponScreen} />
        </main>
      </ErrorBoundary>
      <Footer></Footer>
    </Router>
  )
}

export default App
