import axios from 'axios'
import {
  APPOINTMENT_CREATE_FAILED,
  APPOINTMENT_CREATE_REQUEST,
  APPOINTMENT_CREATE_SUCCESS,
  APPOINTMENT_LIST_FAILED,
  APPOINTMENT_LIST_REQUEST,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_DAYS_AVAILABLE_FAILED,
  APPOINTMENT_DAYS_AVAILABLE_REQUEST,
  APPOINTMENT_DAYS_AVAILABLE_SUCCESS,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED,
  APPOINTMENT_CANCEL_REQUEST,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_CANCEL_FAIL,
  // APPOINTMENT_CANCEL_RESET,
} from '../constants/appointmentConstants'

export const createAppointment = (appointment, executeRecaptcha) => async (
  dispatch
  // getState
) => {
  try {
    dispatch({
      type: APPOINTMENT_CREATE_REQUEST,
    })

    // const {
    //   userLogin: { userInfo },
    // } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    // const token = await executeRecaptcha('appointment_booking')
    // await axios.post(
    //   '/api/auth/validaterecaptcha',
    //   { 'g-recaptcha-response': token },
    //   config
    // )

    const { data } = await axios.post(`/api/appointments`, appointment, config)

    dispatch({
      type: APPOINTMENT_CREATE_SUCCESS,
      payload: data,
    })

    // dispatch({ type: APPOINTMENT_CREATE_RESET })

    localStorage.removeItem('cartItems')
  } catch (error) {
    dispatch({
      type: APPOINTMENT_CREATE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listAppointments = (month) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/appointments/${month}`, config)

    dispatch({
      type: APPOINTMENT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_LIST_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAvailableDays = (date) => async (dispatch) => {
  try {
    dispatch({
      type: APPOINTMENT_DAYS_AVAILABLE_REQUEST,
    })

    // const {
    //   userLogin: { userInfo },
    // } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/appointments/${date}/available`,
      config
    )

    dispatch({
      type: APPOINTMENT_DAYS_AVAILABLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_DAYS_AVAILABLE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAvailableSlots = (date) => async (dispatch) => {
  try {
    dispatch({
      type: APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST,
    })

    // const {
    //   userLogin: { userInfo },
    // } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/appointments/${date}/slots`, config)

    dispatch({
      type: APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const cancelAppointment = (date, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_CANCEL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(
      `/api/appointments/${date}/${id}`,
      { status: 'CANCELLED' },
      config
    )

    dispatch({
      type: APPOINTMENT_CANCEL_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: APPOINTMENT_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
