import React from 'react'
import { Image, Button } from 'react-bootstrap'
import backgroundVideo from '../../images/banner-logo.png'
import './Banner.css'
import { selectLanguage } from '../../actions/sharedActions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const Banner = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    dispatch(selectLanguage(lng))
    moment.locale(lng)
  }

  return (
    <>
      <div id='home' className='banner-wrapper text-center'>
        <Image
          className='banner-img'
          src={backgroundVideo}
          alt={'banner-logo'}
        />
        <h1>Let's build your plate together</h1>
        <Button variant='outline-primary' onClick={() => changeLanguage('fr')}>
          Français
        </Button>
        <Button variant='outline-primary' onClick={() => changeLanguage('en')}>
          English
        </Button>
      </div>
    </>
  )
}

export default Banner
