import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  // FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons'
import {
  loginWithEmailAndPassword,
  // loginWithFacebook,
  loginWithGoogle,
} from '../actions/userActions'
import FormContainer from '../components/FormContainer/FormContainer'
import Loader from '../components/Loader/Loader'
import Message from '../components/Message/Message'
import Meta from '../components/Meta/Meta'

const LoginScreen = ({ history, location }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { t } = useTranslation(['translation', 'phrases'])
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(loginWithEmailAndPassword(email, password))
  }

  return (
    <>
      <Meta title={'Login'} />
      <FormContainer>
        <Row className='py-3'>
          <Col>
            <GoogleLoginButton onClick={() => dispatch(loginWithGoogle())}>
              <span>Google</span>
            </GoogleLoginButton>
            {/* <FacebookLoginButton onClick={() => dispatch(loginWithFacebook())}>
              <span>Facebook</span>
            </FacebookLoginButton>
            <TwitterLoginButton onClick={() => dispatch(loginWithTwitter())}>
              <span>Twitter</span>
            </TwitterLoginButton> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
          <Col className='col-auto'>OR</Col>
          <Col>
            <hr />
          </Col>
        </Row>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader></Loader>}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='email'>
            <Form.Control
              type='email'
              placeholder={t('Enter Email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Control
              type='password'
              placeholder={t('Enter Password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary'>
            {t('Sign In')}
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            <Trans i18nKey='phrases:registerMessage'>
              New Customer?{' '}
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
              >
                Register
              </Link>
            </Trans>
          </Col>
        </Row>
      </FormContainer>
    </>
  )
}

export default LoginScreen
