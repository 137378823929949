import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const TermsAndConditions = ({ onAccept }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleAccept = () => {
    onAccept()
    handleClose()
  }

  const { t } = useTranslation(['terms'])

  return (
    <>
      <Button variant='link' onClick={handleShow}>
        {t('terms:link')}
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('terms:title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('terms:full')}
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {t('terms:close')}
          </Button>
          <Button variant='primary' onClick={handleAccept}>
            {t('terms:accept')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TermsAndConditions
