import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelAppointment,
  listAppointments,
} from '../actions/appointmentActions'
import { validateToken } from '../actions/authActions'
import AppointmentCalendar from '../components/AppointmentCalendar/AppointmentCalendar'
import AppointmentListSummary from '../components/AppointmentListSummary/AppointmentListSummary'
import { APPOINTMENT_CANCEL_RESET } from '../constants/appointmentConstants'
import AdminAppointmentBookingScreen from './AdminAppointmentBookingScreen'
import MaterialTable from 'material-table'

const AppointmentListScreen = ({ history }) => {
  const [selectedDate, setSelectedDate] = useState()
  const [selectedAppointment, setSelectedAppointment] = useState()
  const [openDays, setOpenDays] = useState([])
  const [showFollowup, setShowFollowup] = useState(false)

  const appointmentList = useSelector((state) => state.appointmentList)
  const { appointments } = appointmentList

  const appointmentCancel = useSelector((state) => state.appointmentCancel)
  const { success: appointmentCancelSuccess } = appointmentCancel

  const authToken = useSelector((state) => state.authToken)
  const {
    error: errorAuthToken,
    validating,
    success: successAuthToken,
  } = authToken

  const dispatch = useDispatch()

  useEffect(() => {
    if (errorAuthToken) {
      history.push('/')
    }
    if (!validating && !successAuthToken && !errorAuthToken) {
      history.push('/')
    }
  }, [history, errorAuthToken, successAuthToken, validating])

  useEffect(() => {
    if (appointmentCancelSuccess) {
      dispatch(listAppointments(selectedDate))
      dispatch({ type: APPOINTMENT_CANCEL_RESET })
      // Hack need to to that properly
      setSelectedAppointment({ ...selectedAppointment, status: 'CANCELLED' })
    } else if (appointments) {
      setOpenDays(appointments.map((p) => p.date))
    }
  }, [
    dispatch,
    appointments,
    selectedDate,
    appointmentCancelSuccess,
    selectedAppointment,
  ])

  useEffect(() => {
    dispatch(validateToken())
    const month = moment().startOf('month').format('YYYY-MM-DD')
    const date = moment().startOf('day').format('YYYY-MM-DD')
    dispatch(listAppointments(month))
    setSelectedDate(date)
    // eslint-disable-next-line
  }, []) //Empty array ensures this only run once

  const selectDateHandler = (e) => {
    setSelectedAppointment(null)
    setSelectedDate(moment(e, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DD'))
  }

  const onClickMonth = (month) => {
    setSelectedAppointment(null)
    dispatch(listAppointments(moment(month, 'YYYY-MM-DD').format('YYYY-MM-DD')))
  }

  const onSlotSelected = (slot) => {
    const appointment = appointments.find((element) => element._id === slot._id)
    setSelectedAppointment(appointment)
  }

  const tileClassName = ({ date, view }) => {
    // Disable tiles in month view only
    if (view === 'month') {
      const dateStr = date.toISOString().split('T')[0]
      return openDays.includes(dateStr) ? 'available' : null
    }
  }

  const onCancel = () => {
    if (window.confirm('Are you sure you want to cancel this appointment?')) {
      dispatch(
        cancelAppointment(selectedAppointment.date, selectedAppointment._id)
      )
    }
  }

  const columns = [
    {
      title: 'Confirmation #',
      field: '_id',
      filtering: false,
    },
    {
      title: 'Package',
      field: 'name',
      filtering: false,
    },
    {
      title: 'Client',
      field: 'client_name',
      filtering: false,
    },
    {
      title: 'Date',
      field: 'date',
      filtering: false,
      render: (rowData) => moment(new Date(rowData.date)).format('LL'),
    },
    {
      title: 'Time',
      field: 'time',
      filtering: false,
    },
    {
      title: 'Status',
      field: 'status',
      lookup: { SCHEDULED: 'SCHEDULED', CANCELLED: 'CANCELLED' },
    },
  ]

  return (
    <div id='appointmentlist' className='appointmentlist'>
      <Container>
        <AppointmentCalendar
          onClickMonth={onClickMonth}
          selectDateHandler={selectDateHandler}
          tileClassName={tileClassName}
          maxDetail={'year'}
        />
        <MaterialTable
          title='Appointment List'
          data={appointments}
          columns={columns}
          actions={[
            {
              icon: 'search',
              tooltip: 'See Details',
              onClick: (event, rowData) => onSlotSelected(rowData),
            },
          ]}
          options={{
            filtering: true,
          }}
        />
        <hr />
        <AppointmentListSummary
          appointment={selectedAppointment}
          onProceed={onCancel}
          onFollowup={() => setShowFollowup(true)}
        />
      </Container>
      {showFollowup && selectedAppointment && (
        <>
          <hr />
          <AdminAppointmentBookingScreen
            currentAppointment={{ ...selectedAppointment }}
            onSuccess={() => {
              setShowFollowup(false)
              dispatch(listAppointments(selectedAppointment.date))
            }}
          />
        </>
      )}
    </div>
  )
}

export default AppointmentListScreen
