import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './Experience.css'

const Experience = () => {
  const { t } = useTranslation(['experiences'])
  return (
    <div id='experience' className='experience'>
      <div className='container'>
        <Row>
          <Col xl={7} className='experience-left'>
            <h1>{t('experiences:experience')}</h1>
            <ul>
              <li>{t('experiences:milestone5')}</li>
              <li>{t('experiences:milestone4')}</li>
              <li>{t('experiences:milestone3')}</li>
              <li>{t('experiences:milestone2')}</li>
              <li>{t('experiences:milestone1')}</li>
            </ul>
          </Col>
          <Col className='experience-right'>
            <h1>{t('experiences:Languages')}</h1>
            <ul>
              <li>{t('experiences:French')}</li>
              <li>{t('experiences:Haitian Creole')}</li>
              <li>{t('experiences:English')}</li>
              <li>{t('experiences:Spanish')}</li>
            </ul>
            <p>{t('experiences:message')}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Experience
