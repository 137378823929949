import {
  APPOINTMENT_CREATE_FAILED,
  APPOINTMENT_CREATE_REQUEST,
  APPOINTMENT_CREATE_RESET,
  APPOINTMENT_CREATE_SUCCESS,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_RESET,
  APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS,
  APPOINTMENT_LIST_FAILED,
  APPOINTMENT_LIST_REQUEST,
  APPOINTMENT_LIST_RESET,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_CANCEL_REQUEST,
  APPOINTMENT_CANCEL_SUCCESS,
  APPOINTMENT_CANCEL_FAIL,
  APPOINTMENT_CANCEL_RESET,
} from '../constants/appointmentConstants'
import firebase from '../firebase/firebase'

export const appointmentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENT_CREATE_REQUEST:
      return { ...state, loading: true }
    case APPOINTMENT_CREATE_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true, appointment: action.payload }
    case APPOINTMENT_CREATE_FAILED:
      return { loading: false, error: action.payload }
    case APPOINTMENT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const appointmentListReducer = (
  state = { appointments: [] },
  action
) => {
  switch (action.type) {
    case APPOINTMENT_LIST_REQUEST:
      return { loading: true }
    case APPOINTMENT_LIST_SUCCESS:
      return { loading: false, appointments: action.payload }
    case APPOINTMENT_LIST_FAILED:
      return { loading: false, error: action.payload }
    case APPOINTMENT_LIST_RESET:
      return { appointments: [] }
    default:
      return state
  }
}

export const appointmentSlotListReducer = (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENT_DAY_SLOTS_AVAILABLE_REQUEST:
      return { loading: true }
    case APPOINTMENT_DAY_SLOTS_AVAILABLE_SUCCESS:
      return { loading: false, slots: action.payload }
    case APPOINTMENT_DAY_SLOTS_AVAILABLE_FAILED:
      return { loading: false, error: action.payload }
    case APPOINTMENT_DAY_SLOTS_AVAILABLE_RESET:
      return {}
    default:
      return state
  }
}

export const appointmentCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENT_CANCEL_REQUEST:
      return { loading: true }
    case APPOINTMENT_CANCEL_SUCCESS:
      firebase.analytics.logEvent(action.type.toLowerCase())
      return { loading: false, success: true }
    case APPOINTMENT_CANCEL_FAIL:
      return { loading: false, error: action.payload }
    case APPOINTMENT_CANCEL_RESET:
      return {}
    default:
      return state
  }
}
