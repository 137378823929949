import React from 'react'
import { Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { sendForm } from '../../actions/contactActions'
import './Contacts.css'

const Contacts = () => {
  const { register, handleSubmit, errors } = useForm()
  const { t } = useTranslation(['contacts'])

  const dispatch = useDispatch()

  const contactForm = useSelector((state) => state.contactForm)
  const { error, success } = contactForm

  const onSubmit = (data, r) => {
    dispatch(
      sendForm({
        name: data.name,
        phone: data.phone,
        email: data.email,
        description: data.description,
        referredBy: data.referredBy,
      })
    )
    r.target.reset()
  }

  return (
    <div id='contacts' className='contacts'>
      <Container className='text-center'>
        <h1>{t('contacts:contact me')}</h1>
        <p>{t('contacts:welcome')}</p>
        {success && (
          <span className='success-message'>{t('contacts:success')}</span>
        )}
        {error && <span className='error-message'>{error}</span>}
      </Container>
      <Container className='formcontainer'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-md-6 col-xs-12'>
              {/* NAME INPUT */}
              <div className='text-center'>
                <input
                  type='text'
                  className='form-control'
                  placeholder={t('contacts:Name')}
                  name='name'
                  ref={register({
                    required: t('contacts:Please enter your name'),
                    maxLength: {
                      value: 20,
                      message: t(
                        'contacts:Please enter a name with fewer than 20 characters'
                      ),
                    },
                  })}
                />
                <div className='line'></div>
              </div>
              <span className='error-message'>
                {errors.name && errors.name.message}
              </span>
              {/* PHONE INPUT */}
              <div className='text-center'>
                <input
                  type='text'
                  className='form-control'
                  placeholder={t('contacts:Phone Number')}
                  name='phone'
                  ref={register({
                    required: t('contacts:Please add your phone number'),
                  })}
                />
                <div className='line'></div>
              </div>
              <span className='error-message'>
                {errors.phone && errors.phone.message}
              </span>
              {/* EMAIL INPUT */}
              <div className='text-center'>
                <input
                  type='email'
                  className='form-control'
                  placeholder={t('contacts:Email')}
                  name='email'
                  ref={register({
                    required: t('contacts:Please provide you email'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('contacts:invalid Email'),
                    },
                  })}
                />
                <div className='line'></div>
              </div>
              <span className='error-message'>
                {errors.email && errors.email.message}
              </span>
              {/* REFER INPUT */}
              <div className='text-center'>
                <input
                  type='text'
                  className='form-control'
                  placeholder={t('contacts:Referred by')}
                  name='referredBy'
                  ref={register({})}
                />
                <div className='line'></div>
              </div>
              <span className='error-message'>
                {errors.referredBy && errors.referredBy.message}
              </span>
            </div>
            <div className='col-md-6 col-xs-12'>
              {/* DESCRIPTION */}
              <div className='text-center'>
                <textarea
                  type='text'
                  className='form-control'
                  placeholder={t('contacts:description')}
                  name='description'
                  ref={register({
                    required: t('contacts:description'),
                  })}
                ></textarea>
                <div className='line'></div>
              </div>
              <span className='error-message'>
                {errors.description && errors.description.message}
              </span>
              <button className='btn-main-offer contact-btn' type='submit'>
                {t('contacts:contact me')}
              </button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default Contacts
