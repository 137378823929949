import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import About from '../components/About/About'
import Banner from '../components/Banner/Banner'
import Contacts from '../components/Contacts/Contacts'
import Counseling from '../components/Counseling/Counseling'
import Experience from '../components/Experience/Experience'
import FreeCall from '../components/FreeCall/FreeCall'
import Meta from '../components/Meta/Meta'
import SocialFollow from '../components/SocialFollow/SocialFollow'

const HomeScreen = ({ history }) => {
  const { hash } = useLocation()

  useEffect(() => {
    // if not a hash link scroll to top
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }) // do this on route change

  return (
    <>
      <Meta />
      <Banner />
      <About />
      <Experience />
      <Counseling />
      {/* <Plans history={history} /> */}
      <FreeCall history={history} />
      <SocialFollow />
      <Contacts />
    </>
  )
}

export default HomeScreen
